import { useContext, useMemo, useState } from 'react'
import { FiCheck } from 'react-icons/fi'
import {useSearchParams } from 'react-router-dom'
import Table from './Table'
import useMutateData from '../../hooks/useMutateData'
import { authContext } from '../../context/auth-context'
import RenderIf from '../UI/RenderIf'
import ConfirmationModal from '../Modal/ConfirmationModal'
import DepartmentColumnFilter from './DepartmentColumnFilter'
import singleTaskApi from '../../services/singleTaskApi'

const SingleTasksTable = ({ tasks = []}) => {
  const [showModal, setShowModal] = useState(false)
  const [resourceId, setResourceId] = useState(null)
  const [params] = useSearchParams()
  const { token } = useContext(authContext)
  const { mutate: updateSingleTaskStatus, isLoading } = useMutateData({
    key: 'single-tasks',
    mutationFn: singleTaskApi.updateSingleTaskAttribute,
    successMessage: 'Single Task status updated to COMPLETED successfully',
  })

  const onConfirmDelete = () => {
    updateSingleTaskStatus(
      { id: resourceId, token, attrName: 'status' , value: 'COMPLETED'},
      {
        onSuccess: () => {
          setShowModal(false)
          setResourceId(null)
        },
      }
    )
  }

  const onDismissModal = () => {
    setShowModal(false)
    setResourceId(null)
  }

  const columns = useMemo(
    () => { 
      let cols = [
        {
          Header: 'Id',
          accessor: 'id',
        },
        {
          Header: 'Title',
          accessor: 'task.title',
          Cell: ({ value }) => (
            <p title={value} className='truncate'>
              {value}
            </p>
          ),
        },
        {
          Header: 'VAT',
          accessor: 'task.client.client_id',
        },
        {
          Header: 'Department',
          accessor: 'task.department.name',
          Cell: ({ value }) => (
            <p title={value} className='truncate textCapitalize'>
              {value?.toLowerCase()}
            </p>
          ),
          hasFilter: true,
          Filter: DepartmentColumnFilter
        },
        {
          Header: 'Task Type',
          accessor: 'task.task_type',
        },
        {
          Header: 'Deadline',
          accessor: 'deadline',
          Cell: ({ value }) => (
            <p title={value} className='truncate textCapitalize'>
              {value?.toLowerCase()}
            </p>
          ),
        },
        {
            Header: 'Completion Date',
            accessor: 'completion_date',
            Cell: ({ value }) => (
              <p title={value} className='truncate textCapitalize'>
                {value?.toLowerCase()}
              </p>
            ),
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ value }) => (
            <p title={value} className='truncate textCapitalize'>
              {value?.toLowerCase()}
            </p>
          ),
      },
        {
          Header: 'Actions',
          Cell: ({
            cell: {
              row: { original },
            },
          }) => {
            const { id } = original
            return (
              <div style={{ gap: '10px' }} className='d-flex align-items-center justify-content-end'>
                <FiCheck
                  cursor='pointer'
                  color='#B5B8BA'
                  size={22}
                  onClick={() => {
                    setShowModal(true)
                    setResourceId(id)
                  }}
                />
              </div>
            )
          },
        },
      ] 

      return cols
    },
    []
  )
  return (
    <>
      <RenderIf isTrue={showModal}>
        <ConfirmationModal
          text={'Are you sure you want to change status of this single task to "COMPLETED"?'}
          onDismissModal={onDismissModal}
          onConfirmDelete={onConfirmDelete}
          isLoading={isLoading}
        />
      </RenderIf>
      <div>
        <Table columns={columns} data={tasks} />
      </div>
    </>
  )
}
export default SingleTasksTable
