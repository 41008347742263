import { useContext, useEffect } from "react"
import { authContext } from '../../context/auth-context'
import { Navigate, Outlet, useLocation, useNavigate, useOutletContext, } from 'react-router-dom'
import useGetData from '../../hooks/useGetData'
import departmentsApi from '../../services/deparmentsApi'
import classes from '../../styles/Tasks.module.css'
import Button from '../../components/UI/Button'
import RenderIf from '../../components/UI/RenderIf'
import FormSpinner from '../../components/UI/FormSpinner'
import ViewFilesTable from "../Table/ViewFileTable"


const ListViewFiles = (props) => {
    const navigate = useNavigate()
    const { viewFiles, viewFilesLoading } = useOutletContext()

    return (
        <>
                <div className={`${classes.header_container} mb-4`}>
                    <h4 id='formTitle'>View Files</h4>
                    <Button onClickHandler={() => navigate('/view-file/create-view-file')} text='Create View Files' />
                </div>
                <RenderIf isTrue={viewFilesLoading}>
                    <div className='centerSpinner'>
                        <FormSpinner color='#0d6efd' />
                    </div>
                </RenderIf>
                <RenderIf isTrue={!viewFilesLoading}>
                    <ViewFilesTable viewFiles={viewFiles} />
                </RenderIf>
        </>
    )
}

export default ListViewFiles