import { useFormik } from 'formik'
import FormInput from './UI/FormInput'
import classes from '../styles/Tasks.module.css'
import Button from './UI/Button'
import { validationSchema } from '../utils/validationSchema'
import FormSpinner from './UI/FormSpinner'

const DepartmentForm = ({ onSubmit, isLoading, department }) => {
  const formik = useFormik({
    initialValues: {
      id: department?.id ?? '',
      department_id: department?.department_id ?? '',
      name: department?.name ?? '',
    },
    validationSchema: validationSchema.departmentForm,
    onSubmit,
  })

  return (
    <div className={classes.task_form_wrapper}>
      <form onSubmit={formik.handleSubmit} className={`mt-4 p-5 ${classes.task_form}`}>
        <FormInput
          type='text'
          label='Department Id'
          hasError={!!(formik.touched.department_id && formik.errors.department_id)}
          errorMsg={formik.errors.department_id}
          {...formik.getFieldProps('department_id')}
        />
        <FormInput
          type='text'
          label='Title'
          hasError={!!(formik.touched.name && formik.errors.name)}
          errorMsg={formik.errors.name}
          {...formik.getFieldProps('name')}
        />
      </form>
      <div style={{ marginLeft: 'auto' }}>
        <Button
          text={isLoading ? <FormSpinner /> : !!department ? 'Update Department' : 'Submit'}
          onClickHandler={formik.submitForm}
        />
      </div>
    </div>
  )
}

export default DepartmentForm
