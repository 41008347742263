import axiosInstance from './api'
import moment from 'moment'

class TaskApi {
  async fetchDepartments(token) {
    try {
      const response = await axiosInstance.get('/tasks/department/', {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response.data.data
      
    } catch (error) {
      throw new Error('Failed to load departments')
    }
  }

  async getTasks(token) {
    try {
      const response = await axiosInstance.get('/tasks/task/', {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response.data.data
    } catch (error) {
      throw new Error('Failed to load tasks')
    }
  }

  async createTask({ values, token }) {
    const taskData = {
      task_id: values.taskType,
      task_type: values.taskType,
      view_file_type: values.viewFile,
      client_id: values.client,
      department: +values.department,
      title: values.title,
      repetition: values.repetition,
    }

    if (values.deadline === 'SPECIFIC DAY') {
      taskData.deadline = values.day.toString().length === 1 ? `0${values.day}` : values.day.toString()
    } else if (values.deadline === 'DATE') {
      taskData.deadline = moment(values.date).format('DD/MM/YYYY')
    } else {
      taskData.deadline = values.deadline
    }

    try {
      const response = await axiosInstance.post('/tasks/task/', taskData, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response
    } catch (error) {
      throw new Error('Failed to create task')
    }
  }

  async editTask({ values, token }) {
    const taskData = {
      task_id: values.taskType,
      task_type: values.taskType,
      view_file_type: values.viewFile,
      client_id: values.client,
      department: +values.department,
      title: values.title,
      repetition: values.repetition,
      edit_single_tasks: values.editSingleTasks,
    }

    if (values.deadline === 'SPECIFIC DAY') {
      taskData.deadline = values.day.toString().length === 1 ? `0${values.day}` : values.day.toString()
    } else if (values.deadline === 'DATE') {
      taskData.deadline = moment(values.date).format('DD/MM/YYYY')
    } else {
      taskData.deadline = values.deadline
    }

    try {
      const response = await axiosInstance.put(`/tasks/task/${values.id}/`, taskData, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response
    } catch (error) {
      throw new Error('Failed to update task')
    }
  }

  async deleteTask({ id, token }) {
    try {
      const response = await axiosInstance.delete(`/tasks/task/${id}`, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response
    } catch (error) {
      throw new Error('Failed to delete task')
    }
  }
}

export default new TaskApi()
