import axiosInstance from './api'
import jwt_decode from 'jwt-decode'

class UsersApi {
  async getAuthUser(token) {
    const decodedToken = jwt_decode(token?.access)
    try {
      const response = await axiosInstance.get(`/auth/user/${decodedToken?.user_id}/`, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })
      return response.data.data
    } catch (error) {
      throw new Error('Failed to load user')
    }
  }

  async getUsers(token) {
    try {
      const response = await axiosInstance.get('/auth/user/', {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })
      return response.data.data
    } catch (error) {
      throw new Error('Failed to load users')
    }
  }

  async createUser({ values, token }) {
    const userData = {
      username: values.username,
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      password: values.password,
      user_type: values.userType,
    }
    try {
      const response = await axiosInstance.post('/auth/user/', userData, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })
      return response
    } catch (error) {
      if (error?.response?.status === 400) {
        throw new Error('Username or email already exists')
      } else {
        throw new Error('Failed to update user')
      }
    }
  }

  async updateUser({ values, token }) {
    const userData = {
      username: values.username,
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      password: values.password,
      user_type: values.userType,
    }

    if (values.password === '') {
      delete userData.password
    }

    try {
      const response = await axiosInstance.patch(`/auth/user/${values.id}/`, userData, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })
      return response
    } catch (error) {
      if (error?.response?.status === 400) {
        throw new Error('Username or email already exists')
      } else {
        throw new Error('Failed to update user')
      }
    }
  }

  async deleteUser({ id, token }) {
    try {
      const response = await axiosInstance.delete(`/auth/user/${id}/`, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })
      return response
    } catch (error) {
      throw new Error('Failed to delete user')
    }
  }
}

export default new UsersApi()
