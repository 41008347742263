import React, { useEffect, useState } from 'react'
import { useTable, usePagination, useGlobalFilter, useFilters } from 'react-table'
import { IoChevronForwardOutline, IoChevronBackOutline } from 'react-icons/io5'
import classes from '../../styles/Table.module.css'
import TableSearch from './TableSearch'

const Table = ({ columns, data = [], perPage = 7, apiPagination }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    rows,
    prepareRow,
    setGlobalFilter,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: perPage },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  )

  const { pageSize, pageIndex, globalFilter } = state
  const [pageNum, setPageNum] = useState(pageIndex + 1)

  // Keep the page number in sync after state changes
  useEffect(() => {
    gotoPage(pageNum - 1)
  }, [gotoPage, apiPagination, pageNum])

  return (
    <>
      <TableSearch filter={globalFilter} setFilter={setGlobalFilter} />
      <div className={`${classes.table_container}`}>
        <table {...getTableProps()} className={classes.table}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr style={{ position: 'relative' }} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    style={{
                      width: column.width,
                    }}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                    <div> {column.hasFilter ? column.render('Filter') : null} </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div
        className='px-4 d-flex align-items-center justify-content-between mt-2 flex-wrap'
        style={{ gap: '15px' }}
      >
        <div>
          {rows.length !== 0 && (
            <p className='m-0'>
              showing {pageSize * pageIndex + 1} to {pageSize * pageIndex + 1 + page.length - 1} of{' '}
              {apiPagination ? apiPagination?.count : rows.length} entries
            </p>
          )}
        </div>
        {rows.length !== 0 && (
          <div className='d-flex align-items-center' style={{ gap: '15px' }}>
            <button
              style={{ all: 'unset' }}
              disabled={!canPreviousPage}
              onClick={() => {
                setPageNum(pageIndex)
                previousPage()
              }}
            >
              <IoChevronBackOutline cursor='pointer' size={20} />
            </button>
            <p className='m-0'>
              <span className='text-primaryBlue'>{pageIndex + 1}</span> of{' '}
              {apiPagination ? Math.ceil(apiPagination.count / perPage) : pageOptions.length} pages
            </p>
            <button
              style={{ all: 'unset' }}
              onClick={async () => {
                if (apiPagination && pageIndex === pageOptions.at(-1)) {
                  await apiPagination.fetchNextPage()
                }
                setPageNum(pageIndex + 2)
                nextPage()
              }}
              disabled={apiPagination?.hasNextPage ? false : !canNextPage}
            >
              <IoChevronForwardOutline cursor='pointer' size={20} />
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default Table
