import { createContext, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import usersApi from '../services/usersApi'

export const authContext = createContext({
  authUser: null,
  token: null,
  saveToken: () => null,
  logout: () => null,
})

const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient()
  const getToken = () => {
    const tokenString = localStorage.getItem('token')
    const userToken = JSON.parse(tokenString)
    return userToken
  }
  const [token, setToken] = useState(getToken())

  const { data: authUser } = useQuery('authUser', () => usersApi.getAuthUser(token), {
    enabled: !!token,
  })

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken))
    setToken(userToken)
  }

  const logout = () => {
    localStorage.removeItem('token')
    queryClient.clear()
    setToken(null)
  }

  const ctx = {
    token,
    saveToken,
    logout,
    authUser,
  }

  return <authContext.Provider value={ctx}>{children}</authContext.Provider>
}

export default AuthProvider
