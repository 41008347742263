import useGetData from "../../hooks/useGetData"
import deparmentsApi from "../../services/deparmentsApi"
import { useContext, useState } from "react"
import { authContext } from "../../context/auth-context"
import { useFormik } from 'formik'
import Button from "../UI/Button"
import { validationSchema } from "../../utils/validationSchema"
import classes from '../../styles/Tasks.module.css'
import { useNavigate, useOutletContext, useParams } from "react-router-dom"
import RenderIf from "../UI/RenderIf"
import useMutateData from "../../hooks/useMutateData"
import taskTemplateApi from "../../services/taskTemplateApi"
import ConfirmationModal from '../Modal/ConfirmationModal'
import FormSpinner from "../UI/FormSpinner"

const AssignDepartmentTask = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [departmentIds,setDepartmentIds] = useState([])
    const clients = useOutletContext()
    const params = useParams()
    const { token } = useContext(authContext)
    const { data: departments, isLoading: departmentsLoading } = useGetData({
        queryKey: 'departments',
        queryFn: () => deparmentsApi.getDepartments(token),
    })

    const { mutate: assignDepartmentTasks, isLoading } = useMutateData({
        key: ['tasks', 'task-templates', 'single-tasks'],
        mutationFn: taskTemplateApi.assignTasksToClient,
        successMessage: 'Tasks has been assigned successfully to client',
    })

    const client = clients?.find(client => client?.id === +params?.id)
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            checked: [],
        },
        validationSchema: validationSchema.assignDepartmentTaskForm,
        onSubmit: (values) => {
            setDepartmentIds(values.checked)
            setShowModal(true)
        }
    })
    const onConfirmAssign = () => {
        const values = {client: client.client_id, departments: departmentIds.map(departmentId => parseInt(departmentId))}
        assignDepartmentTasks({values, token},
            {
                onSuccess: () => {
                  setShowModal(false)
                },
            }     
        )
    }
    const onDismissModal = () => {
        setShowModal(false)
    }
    // TODO use isLoading for the call
    return (
        <>
        <div>
            <h4 id='formTitle'>Assign Department's Tasks to Client</h4>
            <Button onClickHandler={() => navigate(-1)} text='< Back' />
        </div>
        <div className={classes.task_form_wrapper}>
            <RenderIf isTrue={departmentsLoading}>
                <div className='centerSpinner'>
                    <FormSpinner color='#0d6efd' />
                </div>
            </RenderIf>
            { !departmentsLoading ? 
                <>
                <form className="mt-4 p-5" onSubmit={formik.handleSubmit} style={{backgroundColor: 'white'}}>
                    {departments.map((department) => {
                        return (
                            <div className="form-check" key={department.id}>
                                <input {...formik.getFieldProps('checked')} style={{cursor:'pointer'}} className="form-check-input" type="checkbox" name="checked" value={department.id} id={department.id}/>
                                <label className="form-check-label" for={department.id} style={{cursor:'pointer'}}>
                                    {department.name}
                                </label>
                            </div>
                        )
                    })}
                    <RenderIf isTrue={formik.errors.checked}>
                            <p style={{color: 'red'}}>{formik.errors.checked}</p>
                    </RenderIf>
                </form>
                <div style={{ marginLeft: 'auto' }}>
                    <Button
                    text={'Submit'}
                    onClickHandler={formik.submitForm}
                    />
                </div>
                </> : null
            }
            <RenderIf isTrue={showModal}>
                <ConfirmationModal
                text="Are you sure you want to assign those departments' tasks to the client?"
                onDismissModal={onDismissModal}
                onConfirmDelete={onConfirmAssign}
                isLoading={isLoading}
                />
            </RenderIf>
        </div>
        </>
    )
}

export default AssignDepartmentTask
