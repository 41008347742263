import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Button from '../UI/Button'
import useMutateData from '../../hooks/useMutateData'
import { useContext, useEffect } from 'react'
import { authContext } from '../../context/auth-context'
import ClientForm from '../ClientForm'
import clientsApi from '../../services/clientsApi'

const EditClient = () => {
  useEffect(() => {
    document.title = 'Edit Client - Analog Group'
  }, [])

  const navigate = useNavigate()
  const clients = useOutletContext()
  const params = useParams()
  const { token } = useContext(authContext)

  const { mutate: editClient, isLoading } = useMutateData({
    key: 'clients',
    mutationFn: clientsApi.editClient,
    successMessage: 'Client has been updated successfully',
  })

  const client = clients?.find(task => task?.id === +params?.id)

  const onSubmit = async values => {
    editClient(
      { values, token },
      {
        onSuccess: () => {
          navigate(-1)
        },
      }
    )
  }

  return (
    <div>
      <div>
        <h4 id='formTitle'>Edit Client</h4>
        <Button onClickHandler={() => navigate(-1)} text='< Back' />
      </div>
      <ClientForm onSubmit={onSubmit} isLoading={isLoading} client={client} />
    </div>
  )
}

export default EditClient
