import classes from '../../styles/UI/ModalActionBtn.module.css'

const ModalActionBtn = ({ text, color, onClickHandler }) => {
  return (
    <button style={{ backgroundColor: color }} onClick={onClickHandler} className={classes.modal_action_btn}>
      {text}
    </button>
  )
}

export default ModalActionBtn
