import { useEffect, useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { FiSearch } from 'react-icons/fi'
import classes from '../../styles/TableSearch.module.css'
import { useSearchParams } from 'react-router-dom'

const DepartmentColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  const  [params] = useSearchParams()

  useEffect(() => {
    setFilter(params.get('departmentName'))
  },[])
  
  // const handleChange = useAsyncDebounce(value => {
  //   return setFilter(value || undefined)
  // }, 500)

  return (
    <span>
      <input
        type='text'
        placeholder='Search Department'
        value={filterValue || ''}
        onChange={event => {
          setFilter(event.target.value)
          // handleChange(event.target.value)
        }}
      />
    </span>
  )
}

export default DepartmentColumnFilter