const Button = ({
  text,
  onClickHandler,
  background = 'linear-gradient(13deg, #2e6092 0%, #2d62de 100%) 0% 0%',
  color = '#fff',
}) => {
  return (
    <button
      style={{
        maxWidth: 'max-content',
        background: background,
        color: color,
      }}
      type='button'
      className='btn btn-sm btn-outline-primary me-2'
      onClick={onClickHandler}
    >
      {text}
    </button>
  )
}

export default Button
