import { yupToFormErrors } from 'formik'
import * as yup from 'yup'

export const validationSchema = {
  taskForm: yup.object({
    title: yup.string().trim().required('Title is required').max(60, 'Title must be less than 60 characters'),
    client: yup.string().trim().required('Client is required'),
    department: yup.string().required('Department is required'),
    taskType: yup.string().trim().required('Task type is required'),
    repetition: yup.string().trim().required('Repetition is required'),
    deadline: yup.string().trim().required('Deadline is required'),
    day: yup.number().min(1, 'Day Of Month should be 01-28').max(28, 'Day Of Month should be 1-28'),
    date: yup.date(),
  }),
  taskTemplateForm: yup.object({
    title: yup.string().trim().required('Title is required').max(60, 'Title must be less than 60 characters'),
    department: yup.string().required('Department is required'),
    taskType: yup.string().trim().required('Task type is required'),
    repetition: yup.string().trim().required('Repetition is required'),
    deadline: yup.string().trim().required('Deadline is required'),
    day: yup.number().min(1, 'Day Of Month should be 01-28').max(28, 'Day Of Month should be 1-28'),
    date: yup.date(),
  }),
  viewFileForm: yup.object({
    title: yup.string().trim().required('Title is required').max(60, 'Title must be less than 60 characters'),
    slug: yup.string().trim().required('Slug is required').matches(/^[A-Z0-9%]{2,5}$/, 'Slug must contain (A-Z,0-9,%) from 2 to 5 characters')
  }),
  assignDepartmentTaskForm: yup.object({
    checked: yup.array().min(1, 'Select at least one department to assign its tasks to the client'),
  }),
  clientForm: yup.object({
    vat: yup.string().trim().required('VAT is required'),
    name: yup.string().trim().required('Name is required'),
  }),
  departmentForm: yup.object({
    department_id: yup.string().trim().required('Department Id is required'),
    name: yup.string().trim().required('Name is required'),
  }),
  userForm: isEditUser =>
    yup.object({
      username: yup.string().trim().required('Required'),
      firstname: yup.string().trim().required('Required'),
      lastname: yup.string().trim().required('Required'),
      email: yup.string().trim().email('Invalid email address').required('Required'),
      password: isEditUser ? yup.string().trim().optional() : yup.string().required('Required'),
    }),
}
