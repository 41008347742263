import { useFormik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { authContext } from '../context/auth-context'
import { toast } from 'react-toastify'
import { Navigate } from 'react-router-dom'
import * as yup from 'yup'

import FormSpinner from '../components/UI/FormSpinner'

import classes from '../styles/Login.module.css'
import logo from '../assets/logo.png'
import axiosInstance from '../services/api'

const Login = () => {
  useEffect(() => {
    document.title = 'Login - Analog Group'
  }, [])
  const [isFormLoading, setIsFormLoading] = useState(false)
  const { token, saveToken } = useContext(authContext)

  const initialValues = {
    username: '',
    password: '',
  }

  const validationSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  })

  const onSubmit = async values => {
    setIsFormLoading(true)
    try {
      const res = await axiosInstance.post(`/login/`, values)
      saveToken(res.data.data)
      setIsFormLoading(false)
    } catch (err) {
      toast.error('Invalid Username or Password')
      setIsFormLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  //Clean up function to prevent state update on an unmounted component
  useEffect(() => () => setIsFormLoading(false), [])

  // If there is a valid token we should not be able to access the the Login screen.
  if (token) return <Navigate to='/' />

  return (
    <div className={classes.login_container}>
      <div className={classes.form_wrapper}>
        <img src={logo} alt='' width={120} />
        <p className={classes.welcome_text}>Welcome!</p>
        <form className={classes.login_form} onSubmit={formik.handleSubmit}>
          <input
            type='text'
            placeholder='Username'
            className={classes.user_input}
            {...formik.getFieldProps('username')}
          />
          <input
            type='password'
            placeholder='Password'
            className={classes.user_input}
            {...formik.getFieldProps('password')}
          />
          <button className={`${classes.login_button} btn`} type='submit'>
            {isFormLoading ? <FormSpinner /> : 'Log in'}
          </button>
        </form>
      </div>
    </div>
  )
}

export default Login
