import { useNavigate } from 'react-router-dom'
import Button from '../UI/Button'
import useMutateData from '../../hooks/useMutateData'
import { useContext, useEffect } from 'react'
import { authContext } from '../../context/auth-context'
import deparmentsApi from '../../services/deparmentsApi'
import DepartmentForm from '../DepartmentForm'

const CreateDepartment = () => {
  useEffect(() => {
    document.title = 'Create Department - Analog Group'
  }, [])

  const navigate = useNavigate()
  const { token } = useContext(authContext)

  const { mutate: createDepartment, isLoading } = useMutateData({
    key: 'departments',
    mutationFn: deparmentsApi.createDepartment,
    successMessage: 'Department has been created successfully',
  })

  const onSubmit = async values => {
    createDepartment(
      { values, token },
      {
        onSuccess: () => {
          navigate(-1)
        },
      }
    )
  }

  return (
    <div>
      <div>
        <h4 id='formTitle'>Create Department</h4>
        <Button onClickHandler={() => navigate(-1)} text='< Back' />
      </div>
      <DepartmentForm onSubmit={onSubmit} isLoading={isLoading} />
    </div>
  )
}

export default CreateDepartment
