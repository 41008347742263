import { useFormik } from 'formik'
import moment from 'moment'
import FormInput from './UI/FormInput'
import classes from '../styles/Tasks.module.css'
import FormSelect from './UI/FormSelect'
import Button from './UI/Button'
import { deadlineOptions, taskRepitionOptions, taskTypeOptions } from '../helpers/select-options'
import RenderIf from './UI/RenderIf'
import { validationSchema } from '../utils/validationSchema'
import FormSpinner from './UI/FormSpinner'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useGetData from '../hooks/useGetData'
import viewFileApi from '../services/viewFileApi'
import { authContext } from '../context/auth-context'

const TaskForm = ({ departments = [], onSubmit, isLoading, task, clients = [], isTemplate=false }) => {
  const { token } = useContext(authContext)
  const [params] = useSearchParams()
  const departmentId = params.get('departmentId')
  // Regex to match the date format
  const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[\\-](0?[1-9]|1[012])[\\-]\d{4}$/
  let formValues = {
    initialValues: {
      id: task?.id ?? '',
      title: task?.title ?? '',
      client: task?.client?.client_id ?? '',
      department: departmentId ?? task?.department?.id ?? '',
      taskType: task?.task_type ?? '',
      repetition: task?.repetition ?? '',
      viewFile: '',
      deadline:
        task?.deadline && dateRegex.test(task?.deadline)
          ? 'DATE'
          : task?.deadline && isNaN(+task?.deadline)
          ? task?.deadline
          : task?.deadline && !isNaN(+task?.deadline)
          ? 'SPECIFIC DAY'
          : '',
      day: task?.deadline && !isNaN(+task?.deadline) ? task?.deadline : '',
      date:
        task?.deadline && dateRegex.test(task?.deadline) ? task?.deadline.split('/').reverse().join('-') : '',
      editSingleTasks: true,
    },
    validationSchema: !isTemplate ? validationSchema.taskForm : validationSchema.taskTemplateForm,
    onSubmit,
  }

  if (isTemplate) {
    delete formValues.initialValues.client
  }


  const formik = useFormik(formValues)
  useEffect(() => {
    if(formik.values.taskType === 'VIEWFILE') {
      formik.setFieldValue('title', formik.values.viewFile)
    }
  }, [formik.values.taskType, formik.values.viewFile])

  const { data: viewFiles, 
          isLoading: isLoadingViewFiles, 
          isError, error } = useGetData({ queryKey: 'view-files', queryFn: () => viewFileApi.getViewFiles(token), enabled: formik.values.taskType === 'VIEWFILE'})
  
  const clientsSelectOptions = clients.map(client => ({
    id: client.id,
    value: client.client_id,
    label: client.name,
  }))
  clientsSelectOptions.unshift({
    id: 0,
    value: '',
    label: 'Select Client',
  })

  const optionsDepartments = useMemo(() =>  {
    
      const transformedDepartments = departments.map(department => ({
        id: department.id,
        value: department.id,
        label: department.name,
      }))
      transformedDepartments.unshift({
        id: 0,
        value: '',
        label: 'Select Department',
      })

      return transformedDepartments
    }, [departments])

    const renderViewFileSelect = () => {
      const options = viewFiles.map(viewFile => ({id: viewFile.id, value: viewFile.id, label: viewFile.title}))
      options.unshift({id: 0, value: '', label: 'Select view file'})
      return <FormSelect
              label='View file'
              options={options}
              {...formik.getFieldProps('viewFile')}
            /> 
    } 

  return (
    <div className={classes.task_form_wrapper}>
      <form onSubmit={formik.handleSubmit} className={`mt-4 p-5 ${classes.task_form}`}>
        {
          formik.values.taskType !== 'VIEWFILE' &&
          <FormInput
            type='text'
            label='Title'
            hasError={!!(formik.touched.title && formik.errors.title)}
            errorMsg={formik.errors.title}
            {...formik.getFieldProps('title')}
          />
        }
        {!isTemplate ? 
        <FormSelect
          label='Client'
          hasError={!!(formik.touched.client && formik.errors.client)}
          errorMsg={formik.errors.client}
          options={clientsSelectOptions}
          {...formik.getFieldProps('client')}
        /> : null }
        <FormSelect
          label='Department'
          hasError={!!(formik.touched.department && formik.errors.department)}
          errorMsg={formik.errors.department}
          options={optionsDepartments}
          {...formik.getFieldProps('department')}
        />
        <FormSelect
          label='Task Type'
          hasError={!!(formik.touched.taskType && formik.errors.taskType)}
          errorMsg={formik.errors.taskType}
          options={taskTypeOptions}
          {...formik.getFieldProps('taskType')}
        />
        {formik.values.taskType === 'VIEWFILE' && !isLoadingViewFiles && renderViewFileSelect()}
        <FormSelect
          label='Repetition'
          hasError={!!(formik.touched.repetition && formik.errors.repetition)}
          errorMsg={formik.errors.repetition}
          options={taskRepitionOptions}
          {...formik.getFieldProps('repetition')}
        />
        <FormSelect
          hasError={!!(formik.touched.deadline && formik.errors.deadline)}
          label='Deadline'
          options={deadlineOptions}
          errorMsg={formik.errors.deadline}
          {...formik.getFieldProps('deadline')}
        />
        <RenderIf isTrue={formik.values.deadline === 'SPECIFIC DAY'}>
          <FormInput
            min='01'
            max='28'
            type='number'
            label='Day Of Month (1-28)'
            hasError={!!(formik.touched.day && formik.errors.day)}
            errorMsg={formik.errors.day}
            {...formik.getFieldProps('day')}
          />
        </RenderIf>
        <RenderIf isTrue={formik.values.deadline === 'DATE'}>
          <FormInput
            type='date'
            min={moment().format('YYYY-MM-DD')}
            hasError={!!(formik.touched.date && formik.errors.date)}
            errorMsg={formik.errors.date}
            {...formik.getFieldProps('date')}
          />
        </RenderIf>
      </form>
      <div style={{ marginLeft: 'auto' }}>
        <RenderIf isTrue={!!task}>
          <Button
            text={isLoading ? <FormSpinner /> : 'Submit'}
            background='#949494'
            onClickHandler={() => {
              formik.setFieldValue('editSingleTasks', false)
              formik.submitForm()
            }}
          />
        </RenderIf>

        <Button
          text={isLoading ? <FormSpinner /> : !!task ? 'Submit and apply to all' : 'Submit'}
          onClickHandler={formik.submitForm}
        />
      </div>
    </div>
  )
}

export default TaskForm
