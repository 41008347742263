import { useContext, useMemo, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { FiEdit } from 'react-icons/fi'
import { createPath, Link, useSearchParams } from 'react-router-dom'
import Table from './Table'
import useMutateData from '../../hooks/useMutateData'
import taskApi from '../../services/taskApi'
import taskTemplateApi from '../../services/taskTemplateApi'
import { authContext } from '../../context/auth-context'
import RenderIf from '../UI/RenderIf'
import ConfirmationModal from '../Modal/ConfirmationModal'
import DepartmentColumnFilter from './DepartmentColumnFilter'

const TasksTable = ({ tasks = [], isTemplate }) => {
  const [showModal, setShowModal] = useState(false)
  const [resourceId, setResourceId] = useState(null)
  const [params] = useSearchParams()
  const { token } = useContext(authContext)
  const { mutate: deleteTask, isLoading } = useMutateData({
    key: !isTemplate ? 'tasks' : 'task-templates',
    mutationFn: !isTemplate ?  taskApi.deleteTask : taskTemplateApi.deleteTaskTemplate,
    successMessage: !isTemplate ? 'Task has been deleted successfully' : 'Task Template has been deleted successfully',
  })

  const onConfirmDelete = () => {
    deleteTask(
      { id: resourceId, token },
      {
        onSuccess: () => {
          setShowModal(false)
          setResourceId(null)
        },
      }
    )
  }

  const onDismissModal = () => {
    setShowModal(false)
    setResourceId(null)
  }

  const columns = useMemo(
    () => { 
      let cols = [
        {
          Header: 'Id',
          accessor: 'id',
        },
        {
          Header: 'Title',
          accessor: 'title',
          Cell: ({ value }) => (
            <p title={value} className='truncate'>
              {value}
            </p>
          ),
        },
        {
          Header: 'VAT',
          accessor: 'client.client_id',
        },
        {
          Header: 'Department',
          accessor: 'department.name',
          Cell: ({ value }) => (
            <p title={value} className='truncate textCapitalize'>
              {value?.toLowerCase()}
            </p>
          ),
          hasFilter: true,
          Filter: DepartmentColumnFilter
        },
        {
          Header: 'Task Type',
          accessor: 'task_type',
        },
        {
          Header: 'Repetition',
          accessor: 'repetition',
          Cell: ({ value }) => (
            <p title={value} className='truncate textCapitalize'>
              {value?.toLowerCase()}
            </p>
          ),
        },
        {
          Header: 'Deadline',
          accessor: 'deadline',
          Cell: ({ value }) => (
            <p title={value} className='truncate textCapitalize'>
              {value?.toLowerCase()}
            </p>
          ),
        },
        {
          Header: 'Actions',
          Cell: ({
            cell: {
              row: { original },
            },
          }) => {
            const { id } = original
            return (
              <div style={{ gap: '10px' }} className='d-flex align-items-center justify-content-end'>
                <Link to={!isTemplate ? `edit-task/${id}` : `edit-task-template/${id}`}>
                  <FiEdit size={20} color='#B5B8BA' />
                </Link>
                <MdDelete
                  cursor='pointer'
                  color='#B5B8BA'
                  size={22}
                  onClick={() => {
                    setShowModal(true)
                    setResourceId(id)
                  }}
                />
              </div>
            )
          },
        },
      ] 

      return !isTemplate ? cols : cols.filter(col => col.Header !== 'VAT')
    },
    []
  )
  return (
    <>
      <RenderIf isTrue={showModal}>
        <ConfirmationModal
          text={!isTemplate ? 'Are you sure you want to delete this task?' :
                               'Are you sure you want to delete this task template?'}
          onDismissModal={onDismissModal}
          onConfirmDelete={onConfirmDelete}
          isLoading={isLoading}
        />
      </RenderIf>
      <div>
        <Table columns={columns} data={tasks} />
      </div>
    </>
  )
}
export default TasksTable
