import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Button from '../UI/Button'
import TaskForm from '../TaskForm'
import useMutateData from '../../hooks/useMutateData'
import taskApi from '../../services/taskApi'
import taskTemplateApi from '../../services/taskTemplateApi'
import { useContext, useEffect } from 'react'
import { authContext } from '../../context/auth-context'

const EditTask = ({ clients, isTemplate=false }) => {
  useEffect(() => {
    document.title = 'Edit Task - Analog Group'
  }, [])

  const navigate = useNavigate()
  const { departments, tasks } = useOutletContext()
  const params = useParams()
  const { token } = useContext(authContext)

  const { mutate: editTask, isLoading } = useMutateData({
    key: !isTemplate ? 'tasks' : 'task-templates',
    mutationFn: !isTemplate ? taskApi.editTask : taskTemplateApi.editTaskTemplate,
    successMessage: !isTemplate ? 'Task has been updated successfully' : 'Task Template has been updated successfully',
  })

  const task = tasks?.find(task => task?.id === +params?.id)

  const onSubmit = async (values, formik) => {
    if(values.taskType === 'VIEWFILE' && values.viewFile === '') {
      formik.setFieldError('viewFile', 'View File is required')
      return
    }

    if (values.deadline === 'SPECIFIC DAY' && values.day === '') {
      formik.setFieldError('day', 'Day of month is required')
      return
    }
    if (values.deadline === 'DATE' && values.date === '') {
      formik.setFieldError('date', 'Date is required')
      return
    }

    if (values.deadline === 'DATE' && values.repetition !== 'NEVER') {
      formik.setFieldError('repetition', 'Invalid repetition for selected deadline, accepted value is Never')
      return
    }

    if (
      values.deadline === 'SPECIFIC DAY' &&
      values.repetition !== 'NEVER' &&
      values.repetition !== 'EVERY MONTH'
    ) {
      formik.setFieldError(
        'repetition',
        'Invalid repetition for selected deadline, accepted values are Never or Every Month'
      )
      return
    }
    editTask(
      { values, token },
      {
        onSuccess: () => {
          navigate(-1)
        },
      }
    )
  }

  return (
    <div>
      <div>
        <h4 id='formTitle'>Edit Task</h4>
        <Button onClickHandler={() => navigate(-1)} text='< Back' />
      </div>
      <TaskForm
        isTemplate={isTemplate}
        departments={departments}
        onSubmit={onSubmit}
        isLoading={isLoading}
        task={task}
        clients={clients}
        
      />
    </div>
  )
}

export default EditTask
