import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from 'react-query'

const useMutateData = ({ key, mutationFn, successMessage }) => {
  const queryClient = useQueryClient()

  const { isLoading, isError, error, mutate } = useMutation(params => mutationFn(params), {
    onSuccess: async () => {
      if (Array.isArray(key)) {
        await Promise.all(key.map(k => queryClient.invalidateQueries({queryKey: k, refetchInactive: true})))
      } else {
        await queryClient.invalidateQueries({queryKey: key, refetchInactive: true})
      }
      if (successMessage) {
        toast.success(successMessage)
      }
    },
    onError: apiError => {
      toast.error(apiError.message)
    },
  })

  return {
    isLoading,
    isError,
    error,
    mutate,
  }
}

export default useMutateData
