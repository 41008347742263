import { useContext, useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useGetData from '../hooks/useGetData'
import Button from '../components/UI/Button'
import RenderIf from '../components/UI/RenderIf'
import { authContext } from '../context/auth-context'
import taskTemplateApi from '../services/taskTemplateApi'
import classes from '../styles/Tasks.module.css'
import TasksTable from '../components/Table/TasksTable'
import FormSpinner from '../components/UI/FormSpinner'

const TaskTemplates = () => {
  const [params] = useSearchParams()
  const departmentId = params.get('departmentId')

  const { token, authUser } = useContext(authContext)
  const { data: departments } = useGetData({
    queryKey: 'departments',
    queryFn: () => taskTemplateApi.fetchDepartments(token),
  })

  const { data: tasks, isLoading: tasksLoading } = useGetData({
    queryKey: 'task-templates',
    queryFn: () => taskTemplateApi.getTasksTemplates(token),
  })

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === '/task-templates'

  useEffect(() => {
    document.title = 'Task Templates - Analog Group'
  }, [])

  if (authUser?.user_type !== 'ADMINISTRATOR') {
    return <Navigate to='/login' />
  }

  return (
    <div className={classes.task_container}>
      <RenderIf isTrue={isExactPath}>
        <div className={`${classes.header_container} mb-4`}>
          <h4 id='formTitle'>Task Templates</h4>
          <Button onClickHandler={() => navigate(`create-task-template/?departmentId=${departmentId}`)} text='Create Task Template' />
        </div>
        <RenderIf isTrue={tasksLoading}>
          <div className='centerSpinner'>
            <FormSpinner color='#0d6efd' />
          </div>
        </RenderIf>
        <RenderIf isTrue={!tasksLoading}>
          <TasksTable tasks={tasks} isTemplate={true} />
        </RenderIf>
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <Outlet context={{ departments, tasks }} />
      </RenderIf>
    </div>
  )
}

export default TaskTemplates
