import { useContext, useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import useGetData from '../hooks/useGetData'
import Button from '../components/UI/Button'
import RenderIf from '../components/UI/RenderIf'
import { authContext } from '../context/auth-context'
import singleTaskApi from '../services/singleTaskApi'
import classes from '../styles/Tasks.module.css'
import FormSpinner from '../components/UI/FormSpinner'
import SingleTasksTable from '../components/Table/SingleTasksTable'

const Tasks = () => {
  const { token, authUser } = useContext(authContext)

  const { data: tasks, isLoading: tasksLoading } = useGetData({
    queryKey: 'single-tasks',
    queryFn: () => singleTaskApi.getSingleTasks(token),
  })

  useEffect(() => {
    document.title = 'Single Tasks - Analog Group'
  }, [])

  if (authUser?.user_type !== 'ADMINISTRATOR') {
    return <Navigate to='/login' />
  }
  
  return (
    <div className={classes.task_container}>
        <div className={`${classes.header_container} mb-4`}>
          <h4 id='formTitle'>Single Tasks</h4>
        </div>
        <RenderIf isTrue={tasksLoading}>
          <div className='centerSpinner'>
            <FormSpinner color='#0d6efd' />
          </div>
        </RenderIf>
        <RenderIf isTrue={!tasksLoading}>
          <SingleTasksTable tasks={tasks} />
        </RenderIf>
    </div>
  )
}

export default Tasks
