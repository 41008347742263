import classes from '../../styles/UI/FormSpinner.module.css'

const FormSpinner = ({ color = '#fff' }) => {
  return (
    <div className={classes.form_spinner}>
      <div
        style={{
          backgroundColor: color,
        }}
        className={classes.bounce1}
      />
      <div
        style={{
          backgroundColor: color,
        }}
        className={classes.bounce2}
      />
      <div
        style={{
          backgroundColor: color,
        }}
      />
    </div>
  )
}

export default FormSpinner
