import { useContext, useMemo, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { FiEdit } from 'react-icons/fi'
import Table from './Table'
import useMutateData from '../../hooks/useMutateData'
import usersApi from '../../services/usersApi'
import { authContext } from '../../context/auth-context'
import ConfirmationModal from '../Modal/ConfirmationModal'
import RenderIf from '../UI/RenderIf'

const UsersTable = ({ getUserValues, users = [] }) => {
  const [showModal, setShowModal] = useState(false)
  const [resourceId, setResourceId] = useState(null)
  const { token } = useContext(authContext)
  const { mutate: deleteUser, isLoading } = useMutateData({
    key: 'users',
    mutationFn: usersApi.deleteUser,
    successMessage: 'User has been deleted successfully',
  })

  const onConfirmDelete = () => {
    deleteUser(
      { id: resourceId, token },
      {
        onSuccess: () => {
          setShowModal(false)
          setResourceId(null)
        },
      }
    )
  }

  const onDismissModal = () => {
    setShowModal(false)
    setResourceId(null)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'user_type',
        Cell: ({ value }) => <p className='textCapitalize'>{value?.toLowerCase()}</p>,
      },
      {
        Header: 'Actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          const { id } = original

          return (
            <div style={{ gap: '10px' }} className='d-flex align-items-center justify-content-end'>
              <FiEdit
                size={20}
                color='#B5B8BA'
                cursor='pointer'
                onClick={() =>
                  getUserValues({
                    id,
                    username: original.username,
                    firstname: original.first_name,
                    lastname: original.last_name,
                    email: original.email,
                    userType: original.user_type,
                  })
                }
              />

              <MdDelete
                cursor='pointer'
                color='#B5B8BA'
                size={22}
                onClick={() => {
                  setResourceId(id)
                  setShowModal(true)
                }}
              />
            </div>
          )
        },
      },
    ],
    [getUserValues]
  )
  return (
    <>
      <RenderIf isTrue={showModal}>
        <ConfirmationModal
          text='Are you sure you want to delete this user?'
          onDismissModal={onDismissModal}
          onConfirmDelete={onConfirmDelete}
          isLoading={isLoading}
        />
      </RenderIf>
      <div>
        <Table columns={columns} data={users} />
      </div>
    </>
  )
}
export default UsersTable
