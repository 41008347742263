import axiosInstance from './api'

class ClientsApi {
  async getClients(token) {
    try {
      const response = await axiosInstance.get('/tasks/client/', {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response.data.data
    } catch (error) {
      throw new Error('Failed to load clients')
    }
  }

  async createClient({ values, token }) {
    const clientData = {
      client_id: values.vat,
      name: values.name,
    }

    try {
      const response = await axiosInstance.post('/tasks/client/', clientData, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response
    } catch (error) {
      throw new Error('Failed to create client')
    }
  }

  async editClient({ values, token }) {
    const clientData = {
      client_id: values.vat,
      name: values.name,
    }

    try {
      const response = await axiosInstance.patch(`/tasks/client/${values.id}/`, clientData, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response
    } catch (error) {
      throw new Error('Failed to update client')
    }
  }

  async deleteClient({ id, token }) {
    try {
      const response = await axiosInstance.delete(`/tasks/client/${id}`, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response
    } catch (error) {
      throw new Error('Failed to delete client')
    }
  }
}

export default new ClientsApi()
