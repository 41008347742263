import { useContext, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { authContext } from '../context/auth-context'

import logo from '../assets/logo.png'
import RenderIf from './UI/RenderIf'
import { ref } from 'yup'

function Header() {
  const { logout, authUser } = useContext(authContext)
  const navigate = useNavigate()
  const links = useRef([])

  const logoutHandler = () => {
    logout()
    navigate('/login')
  }

  const navigateHandler = (path, tabIndex) => {
    links.current.forEach(link => {
      link.style.color = 'rgba(0,0,0,.55)'
    });
    links.current[tabIndex].style.color = 'rgba(0,0,0,.9)'
    navigate(path)
  }
  
  //TODO make a mapper for links and tab names
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white mb-4">
        <div className="container-fluid d-flex">
          <a  className="navbar-brand" href='/'>
              <img className='px-2' src={logo} alt='' width='60' />
          </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className='nav-item' style={{cursor:'pointer'}}>
                  <a class="nav-link" ref={(element) => links.current[0] = element} onClick={() => navigateHandler('/', 0)}>Upload Files</a>
                </li>
                <RenderIf isTrue={authUser?.user_type === 'ADMINISTRATOR'}>
                  <li class="nav-item" style={{cursor:'pointer'}}>
                    <a class="nav-link" ref={(element) => links.current[1] = element} onClick={() => navigateHandler('/users', 1)}>Manage Users</a>
                  </li>
                  <li class="nav-item" style={{cursor:'pointer'}}>
                    <a class="nav-link" ref={(element) => links.current[2] = element} onClick={() => navigateHandler('/tasks', 2)}>Manage Tasks</a>
                  </li>
                  <li class="nav-item" style={{cursor:'pointer'}}>
                    <a class="nav-link" ref={(element) => links.current[3] = element} onClick={() => navigateHandler('/single-tasks', 3)}>Manage Single Tasks</a>
                  </li>
                  <li class="nav-item" style={{cursor:'pointer'}}>
                    <a class="nav-link" ref={(element) => links.current[4] = element} onClick={() => navigateHandler('/task-templates', 4)}>Manage Task Templates</a>
                  </li>
                  <li class="nav-item" style={{cursor:'pointer'}}>
                    <a class="nav-link" ref={(element) => links.current[5] = element} onClick={() => navigateHandler('/view-file/list', 5)}>Manage View Files</a>
                  </li>
                  <li class="nav-item" style={{cursor:'pointer'}}>
                    <a class="nav-link" ref={(element) => links.current[6] = element} onClick={() => navigateHandler('/clients', 6)}>Manage Clients</a>
                  </li>
                  <li class="nav-item" style={{cursor:'pointer'}}>
                    <a class="nav-link" ref={(element) => links.current[7] = element} onClick={() => navigateHandler('/departments/list', 7)}>Manage Departments</a>
                  </li>
                </RenderIf>
              </ul>
              <div style={{marginLeft:'auto'}}>
                  <span className='px-3 link-secondary'>
                    {authUser?.first_name} {authUser?.last_name}
                  </span>
                  <span>
                  <button type='button' className='btn btn-sm btn-outline-primary me-2' onClick={logoutHandler}>
                    Logout
                  </button>
                  </span>
              </div>
            </div>
        </div>
      </nav>
    </>
  )
}

export default Header
