import { useFormik } from 'formik'
import FormInput from './UI/FormInput'
import classes from '../styles/Tasks.module.css'
import Button from './UI/Button'
import { validationSchema } from '../utils/validationSchema'
import FormSpinner from './UI/FormSpinner'

const ClientForm = ({ onSubmit, isLoading, client }) => {
  const formik = useFormik({
    initialValues: {
      id: client?.id ?? '',
      vat: client?.client_id ?? '',
      name: client?.name ?? '',
    },
    validationSchema: validationSchema.clientForm,
    onSubmit,
  })

  return (
    <div className={classes.task_form_wrapper}>
      <form onSubmit={formik.handleSubmit} className={`mt-4 p-5 ${classes.task_form}`}>
        <FormInput
          type='text'
          label='VAT'
          hasError={!!(formik.touched.vat && formik.errors.vat)}
          errorMsg={formik.errors.vat}
          {...formik.getFieldProps('vat')}
        />
        <FormInput
          type='text'
          label='Title'
          hasError={!!(formik.touched.name && formik.errors.name)}
          errorMsg={formik.errors.name}
          {...formik.getFieldProps('name')}
        />
      </form>
      <div style={{ marginLeft: 'auto' }}>
        <Button
          text={isLoading ? <FormSpinner /> : !!client ? 'Update Client' : 'Submit'}
          onClickHandler={formik.submitForm}
        />
      </div>
    </div>
  )
}

export default ClientForm
