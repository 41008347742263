import React, { useContext } from 'react'
import { toast, Slide } from 'react-toastify'
import { Route, Routes } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import Login from './screens/Login'
import Home from './screens/Home'
import Users from './screens/Users'
import RenderIf from './components/UI/RenderIf'
import Tasks from './screens/Tasks'
import Laytout from './containers/Laytout'
import SingleTasks from './screens/SingleTasks'
import TaskTemplates from './screens/TaskTemplates'
import CreateTask from './components/Tasks/CreateTask'
import EditTask from './components/Tasks/EditTask'
import 'react-toastify/dist/ReactToastify.css'
import { authContext } from './context/auth-context'
import { logoutInterceptor } from './services/api'
import Clients from './screens/Clients'
import CreateClient from './components/Clients/CreateClient'
import EditClient from './components/Clients/EditClient'
import useGetData from './hooks/useGetData'
import clientsApi from './services/clientsApi'
import ListDepartments from './components/Departments/ListDepartments'
import Departments from './screens/Departments'
import CreateDepartment from './components/Departments/CreateDepartment'
import EditDepartment from './components/Departments/EditDepartment'
import AssignDepartmentTask from './components/Clients/AssignDepartmentTask'
import ViewFile from './screens/ViewFile'
import CreateViewFile from './components/ViewFile/CreateViewFile'
import EditViewFile from './components/ViewFile/EditViewFile'
import ListViewFiles from './components/ViewFile/ListViewFiles'

toast.configure({
  position: 'top-center',
  autoClose: 3000,
  pauseOnFocusLoss: false,
  transition: Slide,
})

function App() {
  const { logout, token } = useContext(authContext)
  // Log out user if response is 401
  logoutInterceptor(logout)
  const { data: clients, isLoading: clientsLoading } = useGetData({
    queryKey: 'clients',
    queryFn: () => clientsApi.getClients(token),
  })

  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route element={<Laytout />}>
          <Route path='/' element={<Home />} />
          <Route path='/users' element={<Users />} />
          <Route path='/single-tasks' element={<SingleTasks />}/>
          <Route path='/tasks' element={<Tasks />}>
            <Route path='create-task' element={<CreateTask clients={clients} />} />
            <Route path='edit-task/:id' element={<EditTask clients={clients} />} />
          </Route>
          <Route path='/task-templates/' element={<TaskTemplates/>}>
            <Route path='create-task-template' element={<CreateTask isTemplate={true} />}/>
            <Route path='edit-task-template/:id' element={<EditTask isTemplate={true} />}/>
          </Route>
          <Route path='/clients' element={<Clients clients={clients} clientsLoading={clientsLoading} />}>
            <Route path='assign-department-task/:id' element={<AssignDepartmentTask />} />
            <Route path='create-client' element={<CreateClient />} />
            <Route path='edit-client/:id' element={<EditClient />} />
          </Route>
          <Route path='/departments' element={<Departments/>}>
            <Route path='list' element={<ListDepartments />} />
            <Route path='create-department' element={<CreateDepartment />} />
            <Route path='edit-department/:id' element={<EditDepartment />} />
          </Route>
          <Route path='/view-file' element={<ViewFile/>}>
            <Route path='list' element={<ListViewFiles />} />
            <Route path='create-view-file' element={<CreateViewFile />} />
            <Route path='edit-view-file/:id' element={<EditViewFile />} />
          </Route>
        </Route>
      </Routes>
      <RenderIf isTrue={process.env.NODE_ENV === 'development'}>
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
      </RenderIf>
    </>
  )
}

export default App
