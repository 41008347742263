import classes from '../styles/UsersForm.module.css'
import FormSpinner from './UI/FormSpinner'

const UserForm = ({ isEditUser, setIsEditUser, formik, showForm, setShowForm, isLoading }) => {
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.user_form} style={{ display: !showForm ? 'none' : 'block' }}>
        <h6 id='formTitle'>{isEditUser ? 'Edit User' : 'New User'}</h6>
        <div className={`${classes.user_form_card} mt-3`}>
          <div className={classes.user_form_row}>
            <div className={`${classes.user_form_input} m-2 input-group`}>
              <input
                className={`form-control ${
                  formik.touched.firstname && formik.errors.firstname ? classes.error : classes.valid
                }`}
                placeholder='First Name'
                {...formik.getFieldProps('firstname')}
              />
            </div>
            <div className={`${classes.user_form_input} m-2 input-group`}>
              <input
                className={`form-control ${
                  formik.touched.email && formik.errors.lastname ? classes.error : classes.valid
                }`}
                placeholder='Last Name'
                {...formik.getFieldProps('lastname')}
              />
            </div>
          </div>
          <div className={classes.user_form_row}>
            <div className={`${classes.user_form_input} m-2  input-group`}>
              <input
                type='email'
                className={`form-control ${
                  formik.touched.email && formik.errors.email ? classes.error : classes.valid
                }`}
                placeholder='Email'
                {...formik.getFieldProps('email')}
              />
            </div>

            <div className={`${classes.user_form_input} m-2  input-group`}>
              <input
                className={`form-control ${
                  formik.touched.username && formik.errors.username ? classes.error : classes.valid
                }`}
                placeholder='Username'
                {...formik.getFieldProps('username')}
              />
            </div>
          </div>
          <div id='PassAndRole' className={`${classes.user_form_row}`}>
            <div className={`${classes.user_form_input} m-2 input-group`}>
              <input
                type='password'
                className={`form-control ${
                  formik.touched.password && formik.errors.password ? classes.error : classes.valid
                }`}
                placeholder='Password'
                {...formik.getFieldProps('password')}
              />
            </div>

            <div className={`${classes.user_form_select} m-2 input-group`}>
              <select
                className={`form-control ${
                  formik.touched.password && formik.errors.userType ? classes.error : classes.valid
                }`}
                id='user_role'
                {...formik.getFieldProps('userType')}
              >
                <option value='ADMINISTRATOR'>Administrator</option>
                <option value='CUSTOMER'>Customer</option>
              </select>
            </div>
          </div>
        </div>

        <div className={classes.user_form_buttons}>
          <button
            type='button'
            className={`${classes.form_btn} btn m-2`}
            onClick={() => {
              setShowForm(false)
              setIsEditUser(false)
              formik.resetForm()
            }}
          >
            Cancel
          </button>
          <button id='addSubmit' type='submit' className={`${classes.form_btn} btn`}>
            {isLoading ? <FormSpinner /> : isEditUser ? 'Update User' : 'Submit'}
          </button>
        </div>

        <hr className={classes.hr} />
      </div>
    </form>
  )
}

export default UserForm
