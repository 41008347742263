import axiosInstance from './api'

class DepartmentsApi {
    async getDepartments(token) {
      try {
        const response = await axiosInstance.get('/tasks/department/', {
          headers: {
            Authorization: `Bearer ${token?.access}`,
          },
        })
  
        return response.data.data
      } catch (error) {
        throw new Error('Failed to load departments')
      }
    }
  
    async createDepartment({ values, token }) {
      const departmentData = {
        department_id: values.department_id,
        name: values.name,
      }
  
      try {
        const response = await axiosInstance.post('/tasks/department/', departmentData, {
          headers: {
            Authorization: `Bearer ${token?.access}`,
          },
        })
  
        return response
      } catch (error) {
        throw new Error('Failed to create department')
      }
    }
  
    async editDepartment({ values, token }) {
      const departmentData = {
        department_id: values.department_id,
        name: values.name,
      }
  
      try {
        const response = await axiosInstance.patch(`/tasks/department/${values.id}/`, departmentData, {
          headers: {
            Authorization: `Bearer ${token?.access}`,
          },
        })
  
        return response
      } catch (error) {
        throw new Error('Failed to update department')
      }
    }
  
    async deleteDepartment({ id, token }) {
      try {
        const response = await axiosInstance.delete(`/tasks/department/${id}/`, {
          headers: {
            Authorization: `Bearer ${token?.access}`,
          },
        })
  
        return response
      } catch (error) {
        throw new Error('Failed to delete department')
      }
    }
  }

  export default new DepartmentsApi()