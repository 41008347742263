import UploadCard from '../components/UploadCard'
import { useContext, useEffect, useMemo } from 'react'
import { authContext } from '../context/auth-context'
import { Navigate } from 'react-router-dom'
import FileTable from '../components/Table/FileTable'
import classes from '../styles/Tasks.module.css'
import fileApi from '../services/fileApi'
import RenderIf from '../components/UI/RenderIf'
import FormSpinner from '../components/UI/FormSpinner'
import { useInfiniteQuery } from 'react-query'

function Home() {
  const { token } = useContext(authContext)

  const {
    data,
    isLoading: filesLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(['files'], ({ pageParam }) => fileApi.getFiles(token, pageParam), {
    getNextPageParam: lastPage => lastPage?.next?.split('=').at(-1),
  })

  const filesPaginate = {
    count: data?.pages?.[0]?.count ?? 0,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  }

  const files = useMemo(() => data?.pages.map(group => group?.results ?? []).flat(1) ?? [], [data])

  useEffect(() => {
    document.title = 'Files - Analog Group'
  }, [])

  if (!!!token) {
    return <Navigate to='/login' />
  }

  return (
    <>
      <UploadCard />
      <RenderIf isTrue={filesLoading}>
        <div className='centerSpinner'>
          <FormSpinner color='#0d6efd' />
        </div>
      </RenderIf>
      <RenderIf isTrue={!filesLoading}>
        <div className={classes.task_container} style={{ marginTop: '16px' }}>
          <FileTable files={files} perPage={10} filesPaginate={filesPaginate} />
        </div>
      </RenderIf>
    </>
  )
}

export default Home
