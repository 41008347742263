import '../styles/UploadCard.css'
import MyDropzone from './MyDropzone.js'

function UploadCard() {
  return (
    <div className='container'>
      <div className='card upload_card'>
        <div className='card-body text-center'>
          <h4 className='mt-4'>
            <b>Upload your Excel Files</b>
          </h4>
        </div>
        <MyDropzone />
      </div>
    </div>
  )
}

export default UploadCard
