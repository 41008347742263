import { useNavigate } from 'react-router-dom'
import Button from '../UI/Button'
import useMutateData from '../../hooks/useMutateData'
import { useContext, useEffect } from 'react'
import { authContext } from '../../context/auth-context'
import clientsApi from '../../services/clientsApi'
import ClientForm from '../ClientForm'

const CreateClient = () => {
  useEffect(() => {
    document.title = 'Create Client - Analog Group'
  }, [])

  const navigate = useNavigate()
  const { token } = useContext(authContext)

  const { mutate: createClient, isLoading } = useMutateData({
    key: 'clients',
    mutationFn: clientsApi.createClient,
    successMessage: 'Client has been created successfully',
  })

  const onSubmit = async values => {
    createClient(
      { values, token },
      {
        onSuccess: () => {
          navigate(-1)
        },
      }
    )
  }

  return (
    <div>
      <div>
        <h4 id='formTitle'>Create Client</h4>
        <Button onClickHandler={() => navigate(-1)} text='< Back' />
      </div>
      <ClientForm onSubmit={onSubmit} isLoading={isLoading} />
    </div>
  )
}

export default CreateClient
