import classes from '../../styles/FormInput.module.css'
import RenderIf from './RenderIf'

const FormInput = ({ errorMsg, label, hasError, ...restProps }) => {
  return (
    <div className={classes.form_wrapper}>
      <label htmlFor={label}>{label}</label>
      <input {...restProps} id={label} className={hasError ? classes.error : classes.valid} />
      <RenderIf isTrue={hasError}>
        <p className={classes.textError}>{errorMsg}</p>
      </RenderIf>
    </div>
  )
}

export default FormInput
