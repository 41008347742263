import { useFormik } from 'formik'
import FormInput from './UI/FormInput'
import classes from '../styles/Tasks.module.css'
import Button from './UI/Button'
import { validationSchema } from '../utils/validationSchema'
import FormSpinner from './UI/FormSpinner'

const ViewFileForm = ({ onSubmit, isLoading, viewFile }) => {
  const formik = useFormik({
    initialValues: {
      id: viewFile?.id ?? '',
      title: viewFile?.title ?? '',
      slug: viewFile?.slug ?? '',
    },
    validationSchema: validationSchema.viewFileForm,
    onSubmit,
  })

  return (
    <div className={classes.task_form_wrapper}>
      <form onSubmit={formik.handleSubmit} className={`mt-4 p-5 ${classes.task_form}`}>
        <FormInput
          type='text'
          label='Title'
          hasError={!!(formik.touched.title && formik.errors.title)}
          errorMsg={formik.errors.title}
          {...formik.getFieldProps('title')}
        />
        <FormInput
          type='text'
          label='Slug'
          hasError={!!(formik.touched.slug && formik.errors.slug)}
          errorMsg={formik.errors.slug}
          {...formik.getFieldProps('slug')}
        />
      </form>
      <div style={{ marginLeft: 'auto' }}>
        <Button
          text={isLoading ? <FormSpinner /> : !!viewFile ? 'Update View File' : 'Submit'}
          onClickHandler={formik.submitForm}
        />
      </div>
    </div>
  )
}

export default ViewFileForm
