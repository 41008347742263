import { useMemo } from 'react'
import { FaDownload } from 'react-icons/fa'
import Table from './Table'
import moment from 'moment'

const FileTable = ({ files = [], perPage, filesPaginate }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'File Name',
        accessor: 'name',
      },
      {
        Header: 'Client',
        accessor: 'client.name',
      },
      {
        Header: 'Creation Date',
        accessor: 'creation_date',
        Cell: props => {
          //props.value will contain your date
          //you can convert your date here
          const transformed_date = moment(props.value).format('DD-MM-YYYY')
          return <span>{transformed_date}</span>
        },
      },
      {
        Header: 'Actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          const { file } = original
          return (
            <div style={{ gap: '10px' }} className='d-flex align-items-center justify-content-end'>
              <a href={file} target='blank'>
                <FaDownload size={20} color='#B5B8BA' />
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <div>
        <Table columns={columns} data={files} perPage={perPage} apiPagination={filesPaginate} />
      </div>
    </>
  )
}

export default FileTable
