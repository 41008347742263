import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Button from '../UI/Button'
import useMutateData from '../../hooks/useMutateData'
import { useContext, useEffect } from 'react'
import { authContext } from '../../context/auth-context'
import DepartmentForm from '../DepartmentForm'
import departmentsApi from '../../services/deparmentsApi'

const EditDepartment = () => {
  useEffect(() => {
    document.title = 'Edit Departmentf - Analog Group'
  }, [])

  const navigate = useNavigate()
  const { departments } = useOutletContext()
  const params = useParams()
  const { token } = useContext(authContext)

  const { mutate: editDepartment, isLoading } = useMutateData({
    key: 'departments',
    mutationFn: departmentsApi.editDepartment,
    successMessage: 'Department has been updated successfully',
  })

  const department = departments?.find(task => task?.id === +params?.id)

  const onSubmit = async values => {
    editDepartment(
      { values, token },
      {
        onSuccess: () => {
          navigate(-1)
        },
      }
    )
  }

  return (
    <div>
      <div>
        <h4 id='formTitle'>Edit Department</h4>
        <Button onClickHandler={() => navigate(-1)} text='< Back' />
      </div>
      <DepartmentForm onSubmit={onSubmit} isLoading={isLoading} department={department} />
    </div>
  )
}

export default EditDepartment
