import axiosInstance from './api'

class ViewFileApi {
  async getViewFiles(token) {
    try {
      const response = await axiosInstance.get('/tasks/view-file/', {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response.data.data
    } catch (error) {
      throw new Error('Failed to load view file')
    }
  }

  async createViewFile({ values, token }) {
    const viewFileData = {
      title: values.title,
      slug: values.slug,
    }

    try {
      const response = await axiosInstance.post('/tasks/view-file/', viewFileData, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response
    } catch (error) {
      throw new Error('Failed to create view file')
    }
  }

  async editViewFile({ values, token }) {
    const ViewFileData = {
        title: values.title,
        slug: values.slug,
    }

    try {
      const response = await axiosInstance.patch(`/tasks/view-file/${values.id}/`, ViewFileData, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response
    } catch (error) {
      throw new Error('Failed to update view file')
    }
  }

  async deleteViewFile({ id, token }) {
    try {
      const response = await axiosInstance.delete(`/tasks/view-file/${id}`, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response
    } catch (error) {
      throw new Error('Failed to delete view file')
    }
  }
}

export default new ViewFileApi()
