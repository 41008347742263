import classes from '../../styles/FormInput.module.css'
import RenderIf from './RenderIf'

const FormSelect = ({ errorMsg, label, hasError, options = [], ...restProps }) => {
  return (
    <div className={classes.form_wrapper}>
      <label htmlFor={label}>{label}</label>
      <select id={label} {...restProps} className={hasError ? classes.error : classes.valid}>
        {options.map(option => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <RenderIf isTrue={hasError}>
        <p className={classes.textError}>{errorMsg}</p>
      </RenderIf>
    </div>
  )
}

export default FormSelect
