import { useState, useContext } from 'react'
import { useFormik } from 'formik'
import Button from './UI/Button'
import UsersTable from './Table/UsersTable'
import { authContext } from '../context/auth-context'
import useGetData from '../hooks/useGetData'
import usersApi from '../services/usersApi'
import UserForm from './UserForm'
import useMutateData from '../hooks/useMutateData'
import classes from '../styles/UsersForm.module.css'
import { validationSchema } from '../utils/validationSchema'
import RenderIf from './UI/RenderIf'
import FormSpinner from './UI/FormSpinner'

function UsersContainer() {
  const { token } = useContext(authContext)
  const [showForm, setShowForm] = useState(false)
  const [isEditUser, setIsEditUser] = useState(false)

  const { data: users, isLoading: usersLoading } = useGetData({
    queryKey: 'users',
    queryFn: () => usersApi.getUsers(token),
  })
  const { mutate, isLoading: isCreateEditUserLoading } = useMutateData({
    key: 'users',
    mutationFn: isEditUser ? usersApi.updateUser : usersApi.createUser,
    successMessage: isEditUser ? 'User has been updated successfully' : 'User has been created successfully',
  })

  const initialValues = {
    id: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    userType: 'ADMINISTRATOR',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: () => validationSchema.userForm(isEditUser),
    onSubmit: values => {
      mutate(
        { values, token },
        {
          onSuccess: () => {
            formik.resetForm()
            setShowForm(false)
            setIsEditUser(false)
            setShowForm(false)
          },
        }
      )
    },
  })

  const getUserValues = user => {
    formik.setValues(user)
    setIsEditUser(true)
    setShowForm(true)
  }

  return (
    <div className={classes.user_container}>
      <div className=''>
        <UserForm
          isEditUser={isEditUser}
          setIsEditUser={setIsEditUser}
          formik={formik}
          showForm={showForm}
          setShowForm={setShowForm}
          isLoading={isCreateEditUserLoading}
        />

        <div className={`${classes.user_list} mb-4`}>
          <h4>
            <b>Users</b>
          </h4>
          <Button text='+ New User' onClickHandler={() => setShowForm(true)} />
        </div>
      </div>
      <RenderIf isTrue={usersLoading}>
        <div className='centerSpinner'>
          <FormSpinner color='#0d6efd' />
        </div>
      </RenderIf>
      <RenderIf isTrue={!usersLoading}>
        <UsersTable getUserValues={getUserValues} users={users} />
      </RenderIf>
    </div>
  )
}

export default UsersContainer
