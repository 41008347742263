import ReactDOM from 'react-dom'
import { AiOutlineCloseCircle, AiOutlineWarning } from 'react-icons/ai'
import classes from '../../styles/ConfirmationModal.module.css'
import FormSpinner from '../UI/FormSpinner'
import ModalActionBtn from './ModalActionBtn'

const ConfirmationModal = ({ text, onConfirmDelete, onDismissModal, isLoading }) => {
  const overlay = document.getElementById('overlays')
  return ReactDOM.createPortal(
    <>
      <div className={classes.backdrop} onClick={onDismissModal} />
      <div className={classes.modal}>
        <div className='d-flex justify-content-end'>
          <AiOutlineCloseCircle color='#6c757d' size={25} cursor='pointer' onClick={onDismissModal} />
        </div>
        <div className={classes.modal_content_wrapper}>
          <AiOutlineWarning color='#e30000' size={80} />
          <p className={classes.modal_text}>{text}</p>
        </div>

        <div className={classes.modal_buttons_wrapper}>
          <ModalActionBtn color='#6c757d' text='No, cancel' onClickHandler={onDismissModal} />
          <ModalActionBtn
            color='#e30000'
            text={isLoading ? <FormSpinner /> : "Yes, I'm sure"}
            onClickHandler={onConfirmDelete}
          />
        </div>
      </div>
    </>,
    overlay
  )
}

export default ConfirmationModal
