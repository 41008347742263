import { useContext, useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Button from '../components/UI/Button'
import RenderIf from '../components/UI/RenderIf'
import { authContext } from '../context/auth-context'
import classes from '../styles/Tasks.module.css'
import FormSpinner from '../components/UI/FormSpinner'
import ClientsTable from '../components/Table/ClientsTable'

const Clients = ({ clients = [], clientsLoading }) => {
  const { authUser } = useContext(authContext)

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === '/clients'

  useEffect(() => {
    document.title = 'Clients - Analog Group'
  }, [])

  if (authUser?.user_type !== 'ADMINISTRATOR') {
    return <Navigate to='/login' />
  }

  return (
    <div className={classes.task_container}>
      <RenderIf isTrue={isExactPath}>
        <div className={`${classes.header_container} mb-4`}>
          <h4 id='formTitle'>Clients</h4>
          <Button onClickHandler={() => navigate('create-client')} text='Create Client' />
        </div>
        <RenderIf isTrue={clientsLoading}>
          <div className='centerSpinner'>
            <FormSpinner color='#0d6efd' />
          </div>
        </RenderIf>
        <RenderIf isTrue={!clientsLoading}>
          <ClientsTable clients={clients} />
        </RenderIf>
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <Outlet context={clients} />
      </RenderIf>
    </div>
  )
}

export default Clients
