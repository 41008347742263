import { useContext, useEffect } from "react"
import { authContext } from '../context/auth-context'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import useGetData from '../hooks/useGetData'
import departmentsApi from '../services/deparmentsApi'
import classes from '../styles/Tasks.module.css'
import Button from '../components/UI/Button'

const Departments = (props) => {
    const { token, authUser } = useContext(authContext)

    const { data: departments, isLoading: departmentsLoading } = useGetData({
        queryKey: 'departments',
        queryFn: () => departmentsApi.getDepartments(token),
    })
    
    useEffect(() => {
        document.title = 'Departments - Analog Group'
      }, [])

    if (authUser?.user_type !== 'ADMINISTRATOR') {
        return <Navigate to='/login' />
    }

    return (
        <>
            <div className={classes.task_container}>
                <Outlet context={{departments, departmentsLoading}}/>
            </div>
        </>
    )
}

export default Departments