import axios from 'axios'
import { toast } from 'react-toastify'

const api =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL_LOCAL
    : process.env.REACT_APP_API_URL_PRODUCTION

const axiosInstance = axios.create({
  baseURL: api,
})

export default axiosInstance

export const logoutInterceptor = logout => {
  axiosInstance.interceptors.response.use(
    response => response,

    error => {
      const originalConfig = error.config
      if (originalConfig.url !== '/login/' && error.response) {
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true
          logout()
          toast.error('Your session has expired. Please login again.', {
            toastId: 'logoutId',
          })
        }
      }
      return Promise.reject(error)
    }
  )
}
