import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './styles/index.css'
import 'bootstrap/dist/js/bootstrap.min'
import App from './App'
import { QueryClient, QueryClientProvider } from 'react-query'
import AuthProvider from './context/auth-context'
const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)
