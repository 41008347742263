import axiosInstance from './api'

class SingleTaskApi {
async getSingleTasks(token) {
    try {
        const response = await axiosInstance.get('/tasks/single-task/', {
        headers: {
            Authorization: `Bearer ${token?.access}`,
        },
        })

        return response.data.data
    } catch (error) {
        throw new Error('Failed to load tasks')
    }
    }

  async updateSingleTaskAttribute({ id, token, attrName, value }) {
    let body = {
        [attrName]: value
    }
    try {
      const response = await axiosInstance.patch(`/tasks/single-task/${id}/`, body, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response.data.data
      
    } catch (error) {
      throw new Error(`Failed to update single task ${attrName}`)
    }
  }
}

export default new SingleTaskApi()