import { useContext } from 'react'
import { useQuery } from 'react-query'
import { authContext } from '../context/auth-context'

const useGetData = ({ queryKey, queryFn, enabled }) => {
  const { token } = useContext(authContext)
  const { isLoading, data, isError, error } = useQuery(queryKey, queryFn, {
    enabled: enabled===undefined ? !!token : enabled ,
    refetchOnMount: false,// only fetch if token is present,
  })
  
  return {
    isLoading,
    data,
    isError,
    error,
  }
}

export default useGetData
