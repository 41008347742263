import { useContext, useMemo, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { FiEdit } from 'react-icons/fi'
import { FaTasks } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Table from './Table'
import useMutateData from '../../hooks/useMutateData'
import { authContext } from '../../context/auth-context'
import RenderIf from '../UI/RenderIf'
import ConfirmationModal from '../Modal/ConfirmationModal'
import clientsApi from '../../services/clientsApi'

const ClientsTable = ({ clients = [] }) => {
  const [showModal, setShowModal] = useState(false)
  const [resourceId, setResourceId] = useState(null)
  const { token } = useContext(authContext)
  const { mutate: deleteClient, isLoading } = useMutateData({
    key: 'clients',
    mutationFn: clientsApi.deleteClient,
    successMessage: 'Client has been deleted successfully',
  })

  const onConfirmDelete = () => {
    deleteClient(
      { id: resourceId, token },
      {
        onSuccess: () => {
          setShowModal(false)
          setResourceId(null)
        },
      }
    )
  }

  const onDismissModal = () => {
    setShowModal(false)
    setResourceId(null)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'VAT',
        accessor: 'client_id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          const { id } = original
          return (
            <div style={{ gap: '10px' }} className='d-flex align-items-center justify-content-end'>
              <Link to={`assign-department-task/${id}`}>
                <FaTasks size={20} color='#B5B8BA' />
              </Link>
              <Link to={`edit-client/${id}`}>
                <FiEdit size={20} color='#B5B8BA' />
              </Link>
              <MdDelete
                cursor='pointer'
                color='#B5B8BA'
                size={22}
                onClick={() => {
                  setShowModal(true)
                  setResourceId(id)
                }}
              />
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <RenderIf isTrue={showModal}>
        <ConfirmationModal
          text='Are you sure you want to delete this client?'
          onDismissModal={onDismissModal}
          onConfirmDelete={onConfirmDelete}
          isLoading={isLoading}
        />
      </RenderIf>
      <div>
        <Table columns={columns} data={clients} />
      </div>
    </>
  )
}

export default ClientsTable
