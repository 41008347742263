import { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { FiSearch } from 'react-icons/fi'
import classes from '../../styles/TableSearch.module.css'

const TableSearch = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter)

  const handleChange = useAsyncDebounce(value => {
    return setFilter(value || undefined)
  }, 500)

  return (
    <div className={classes.table_search}>
      <input
        type='text'
        placeholder='Search'
        value={value || ''}
        onChange={event => {
          setValue(event.target.value)
          handleChange(event.target.value)
        }}
      />
      <FiSearch />
    </div>
  )
}

export default TableSearch
