import axiosInstance from './api'

class FileApi {
  async getFiles(token, pageParam = 1) {
    try {
      const response = await axiosInstance.get(`/tasks/task-file-paginated?page=${pageParam}`, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })

      return response.data.data
    } catch (error) {
      throw new Error('Failed to load files')
    }
  }

  async submitFiles({ files, token }) {
    try {
      const formData = new FormData()
      files.forEach(file => formData.append('file', file))

      return await axiosInstance.post('/tasks/file-upload/upload/', formData, {
        headers: {
          Authorization: `Bearer ${token?.access}`,
        },
      })
    } catch (error) {
      if (
        error &&
        (error.response?.status === 400 || error.response?.status === 403 || error.response?.status === 401)
      ) {
        throw new Error(error.response?.data?.data?.detail)
      } else {
        throw new Error('Something went wrong')
      }
    }
  }
}

export default new FileApi()
