import { useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import UsersContainer from '../components/UsersContainer'
import { authContext } from '../context/auth-context'

function Users() {
  const { authUser } = useContext(authContext)

  useEffect(() => {
    document.title = 'Users - Analog Group'
  }, [])

  if (authUser?.user_type !== 'ADMINISTRATOR') {
    return <Navigate to='/login' />
  }
  return (
    <div>
      <UsersContainer />
    </div>
  )
}

export default Users
