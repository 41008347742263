import React, { useCallback, useContext } from 'react'
import { useDropzone } from 'react-dropzone'
import { MdDelete } from 'react-icons/md'
import { toast } from 'react-toastify'
import { authContext } from '../context/auth-context'
import fileApi from '../services/fileApi'
import Button from './UI/Button'
import { useQueryClient } from 'react-query'

function MyDropzone() {
  const { token } = useContext(authContext)
  const [files, setFiles] = React.useState([])
  const queryClient = useQueryClient()
  const onDrop = useCallback(
    acceptedFiles => {
      const accFiles = acceptedFiles.map(file => file)
      setFiles(prevState => [...prevState, ...accFiles])
    },
    [setFiles]
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  })

  const onRemoveFile = name => {
    const filteredFiles = files.filter((file, i) => file.name + i !== name)

    setFiles(filteredFiles)
  }

  const onSubmitFiles = () => {
    fileApi
      .submitFiles({ files: files, token: token })
      .then(_ => {
        toast.success('Files uploaded successfully')
        setFiles([])
        queryClient.invalidateQueries('files')
      })
      .catch(err => {
        toast.error(err.message)
      })
  }

  const displayFiles = files.map((file, i) => (
    <div key={i} className='d-flex justify-content-between align-items-center'>
      <p>{file.path}</p>
      <MdDelete size={20} color='#d32f2f' cursor='pointer' onClick={() => onRemoveFile(file.path + i)} />
    </div>
  ))

  return (
    <section className='container'>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <h6>
          Drag & drop some files here, <br />
          or click to select files
        </h6>
        <p className='mb-3'>Supported File Type xlsm, xlsx, pdf</p>
        <Button text='Choose Files' onClickHandler={open} />
      </div>

      {files.length !== 0 && (
        <aside>
          <div className='d-flex justify-content-between'>
            <h4>Files</h4>
            <button
              type='button'
              className='btn btn-sm btn-outline-primary me-2 mb-1 upload_btn '
              onClick={onSubmitFiles}
            >
              Upload
            </button>
          </div>

          <div>{displayFiles}</div>
        </aside>
      )}
    </section>
  )
}

export default MyDropzone
