export const taskTypeOptions = [
  { id: 1, value: '', label: 'Select Task Type' },
  { id: 2, value: 'VAT', label: 'VAT' },
  { id: 3, value: '20%', label: '20%' },
  { id: 4, value: '3%', label: '3%' },
  { id: 5, value: 'PT', label: 'PT' },
  { id: 6, value: 'PCF', label: 'PCF' },
  { id: 7, value: 'ICCF', label: 'ICCF' },
  { id: 8, value: 'PFM', label: 'PFM' },
  { id: 9, value: 'AFM', label: 'AFM' },
  { id: 10, value: 'VIEWFILE', label: 'View File' },
]

export const taskRepitionOptions = [
  { id: 1, value: '', label: 'Select Repetition' },
  { id: 2, value: 'NEVER', label: 'Never' },
  { id: 3, value: 'EVERY WEEK', label: 'Every Week' },
  { id: 4, value: 'EVERY MONTH', label: 'Every Month' },
  { id: 5, value: '2 TIMES PER MONTH', label: '2 Times Per Month' },
]

export const deadlineOptions = [
  { id: 1, value: '', label: 'Select Deadline' },
  { id: 2, value: 'SPECIFIC DAY', label: 'Day Of Month' },
  { id: 3, value: 'DATE', label: 'Date - DD/MM/YYYY' },
  { id: 4, value: 'LAST WORKDAY OF MONTH', label: 'Last Workday Of Month' },
  { id: 5, value: 'LAST DAY OF MONTH', label: 'Last Day Of Month' },
  { id: 6, value: 'EVERY 15 OF MONTH', label: 'Every 15 Of Month' },
]
